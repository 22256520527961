export default function getDynamicRoute(currentDynamicRoutes, wantedLanguage) {
  if (!currentDynamicRoutes) {
    return '/'
  }

  if (!(wantedLanguage in currentDynamicRoutes)) {
    return '/'
  }

  return currentDynamicRoutes[wantedLanguage]
}
