import styled, { css } from 'styled-components'

const Content = styled.div`
  margin: 0 auto;
  width: 1160px;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  /* width: 1440px; */
  width: 1448px;

  ${props =>
    props.intro &&
    css`
      margin-top: 3rem;
      margin-top: 2.95rem; /* fix for line-height with first element. */
      margin-top: 4.25rem;

      @media (max-width: 420px) {
        /* make some room for lang and menu */
        margin-top: 4rem;
      }
    `}
`

export default Content
