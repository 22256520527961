export default {
  colors: {
    primary: '#e63b28',
    dark: '#393a39',
  },
  fonts: {
    serif:
      'font-family: "Mercury Text G1 A", "Mercury Text G1 B", "Times New Roman", Times, serif;',
    sansSerif: 'font-family: "BlairITCStd", sans-serif;',
  },
}
