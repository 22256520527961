import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import initialsImg from '../images/gasser-partner-initials-red.svg'
import initialsWhiteImg from '../images/gasser-partner-initials-white.svg'
import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'

/*
const Icon = styled.div`
  width: 40px;
  height: 40px;
  mix-blend-mode: difference;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 103;
  perspective: none;
  transform: none;
`

const Inner = styled.div`
  width: 40px;
  height: 40px;
  background: ${props => props.theme.colors.primary};
  mix-blend-mode: difference;
`
*/

const StyledLink = styled(Link)`
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 100;
  perspective: none;
  transform: none;
  display: block;

  ${props =>
    props.fixed &&
    css`
      position: fixed;
      z-index: 99;

      @media (max-width: 1340px) {
        display: none;
      }
    `}
`

const Initials = styled.img`
  display: block;
  /* mix-blend-mode: difference; */

  width: 20px;
  height: auto;
`

const Brand = ({ color, fixed, ...props }) => {
  const i18n = useContext(I18nContext)

  return (
    <StyledLink
      to={`/${i18n.currentLocale}`}
      aria-label="Home - Gasser Partner"
      fixed={fixed ? 1 : 0}
      {...props}
    >
      <Initials
        src={color === 'white' ? initialsWhiteImg : initialsImg}
        alt=""
      />
    </StyledLink>
  )
}

export default Brand
