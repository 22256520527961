export default {
  partnerStatus: ['Partner', 'Senior Partner', 'Partnerin', 'Senior Partnerin'],
  i18n: {
    locales: ['de', 'en'],
    defaultLocale: 'en',
    routes: {
      '/about-us/': {
        en: '/en/about-us',
        de: '/de/ueber-uns',
      },
      '/career/': { en: '/en/career', de: '/de/karriere' },
      '/competences/': { en: '/en/competences', de: '/de/taetigkeitsbereiche' },
      '/contact/': { en: '/en/contact', de: '/de/kontakt' },
      '/contact/success/': {
        en: '/en/contact/success',
        de: '/de/kontakt/erfolg',
      },
      '/privacy-policy/': { en: '/en/privacy-policy', de: '/de/datenschutz' },
      '/': { en: '/en', de: '/de' },
      '/home/': { en: '/en', de: '/de' },
      '/imprint/': { en: '/en/imprint', de: '/de/impressum' },
      '/news/': { en: '/en/news', de: '/de/news' },
      '/publications/': { en: '/en/publications', de: '/de/publikationen' },
      '/team/': { en: '/en/team', de: '/de/team' },
      '/best-wishes/': { en: '/en/best-wishes', de: '/' },
    },
  },
}
