import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled, {
  css,
  createGlobalStyle,
  ThemeProvider,
} from 'styled-components'
import Helmet from 'react-helmet'

import Header from './Header'
import Footer from './Footer'
import MenuButton from './MenuButton'
import Menu from './Menu'
import Brand from './Brand'
import Language from './Language'
import theme from '../theme'
import hasScrollbar from '../utils/hasScrollbar'
import Consent from './Consent'

const GlobalStyle = createGlobalStyle`

  @font-face{
    font-family: 'BlairITCStd';
    src: url("/fonts/fonts/2F4A7D_0_0.eot");
    src: url("/fonts/2F4A7D_0_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/2F4A7D_0_0.woff2") format("woff2"),
    url("/fonts/2F4A7D_0_0.woff") format("woff"),
    url("/fonts/2F4A7D_0_0.ttf") format("truetype"),
    url("/fonts/2F4A7D_0_0.svg#wf") format("svg")
  }

  html {
    box-sizing: border-box;
    /* overflow: auto; */
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  html, body, body > div, body > div > div {
    width: 100%;
    min-height: 100vh;
  }

  /*
  @media screen and (min-width: 960px) {
    html {
      margin-left: calc(100vw - 100%);
      margin-right: 0;
    }
  }
  */

  body {
    font-family: "Mercury SSm A", "Mercury SSm B", "Times New Roman", Times, serif;
    font-family: "Mercury Text G1 A", "Mercury Text G1 B", "Times New Roman", Times, serif;
    line-height: 1.4;
    letter-spacing: -0.011em;
    color: ${props => props.theme.colors.primary};
    font-size: 20px;

    @media (max-width: 1140px) {
      font-size: 18px;
    }

    @media (max-width: 680px) {
      font-size: 16px;
    }

    ${props =>
      props.isOverlayOpen &&
      css`
        overflow-y: hidden;
        height: 100vh;
      `}
  }


  body.has-scrollbar {
    padding-right: 15px;
  }

  a {
    color: inherit;
  }

  address {
    font-style: inherit;
  }

  ul {
    padding-left: 1.125rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  h2, h3 {
    font-weight: normal;
    margin-bottom: 0;
  }
`

const Article = styled.article`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    flex-shrink: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`

const Main = styled.main`
  overflow: hidden;
  flex: 1;
`

const Layout = ({ children, showLogo = false }) => {
  const [overlay, setOverlay] = useState(false)
  const [expertise, setExpertise] = useState(false)

  const onToggleOverlay = () => {
    const state = !overlay
    setOverlay(state)

    const scrollbar = hasScrollbar()
    if (state && scrollbar) {
      document.body.classList.add('has-scrollbar')
    } else {
      document.body.classList.remove('has-scrollbar')
    }

    if (!state) {
      setExpertise(false)
    }
  }

  useEffect(() => {
    if (!overlay) {
      document.body.classList.remove('has-scrollbar')
    }
  }, [overlay])

  const onExpertiseToggle = () => {
    setExpertise(!expertise)
  }

  const onOverlayClose = () => {
    setOverlay(false)
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              titleDE
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <Helmet>
            <link
              rel="stylesheet"
              href="https://cloud.typography.com/6895874/6768012/css/fonts.css"
              media="print"
              onLoad="this.media='all'"
            />
          </Helmet>
          <div>
            <Article>
              <GlobalStyle isOverlayOpen={overlay} />
              <Menu
                isOverlayOpen={overlay}
                isExpertiseOpen={expertise}
                onExpertiseToggle={onExpertiseToggle}
                onOverlayClose={onOverlayClose}
              />
              <Language isOverlayOpen={overlay} />
              <Brand fixed />
              <MenuButton onClick={onToggleOverlay} isOverlayOpen={overlay} />
              <Header
                siteTitle={data.site.siteMetadata.titleDE}
                showLogo={showLogo}
              />
              <Main>{children}</Main>
              <Footer />
            </Article>
            <Consent />
          </div>
        </ThemeProvider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showLogo: PropTypes.bool,
}

export default Layout
