import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'

import Content from './Content'
import logoRedDeImg from '../images/gasser-partner-logo-red-de.svg'
import logoRedEnImg from '../images/gasser-partner-logo-red-en.svg'
import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'

const Wrapper = styled.header`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
`

const Inner = styled(Content)`
  text-align: center;
`

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 352px;
  height: auto;
  max-width: 100%;
`

const StyledLink = styled(Link)`
  display: inline-block;

  @media (max-width: 620px) {
    position: relative;
    top: 50px;
  }

  @media (max-width: 420px) {
    top: 20px;
  }
`

const Header = ({ showLogo = false }) => {
  const intl = useIntl()
  const i18n = useContext(I18nContext)

  const logoRedImg = i18n.currentLocale === 'de' ? logoRedDeImg : logoRedEnImg

  return (
    <Wrapper>
      <Inner>
        {showLogo && (
          <StyledLink to={`/${i18n.currentLocale}`}>
            <Logo src={logoRedImg} alt={intl.formatMessage({ id: 'title' })} />
          </StyledLink>
        )}
      </Inner>
    </Wrapper>
  )
}

Header.propTypes = {
  showLogo: PropTypes.bool,
}

export default Header
