import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { useIntl } from 'react-intl'

import officeImg from '../images/gasser_partner_office.jpg'
import logoWhiteDeImg from '../images/gasser-partner-logo-white-de.svg'
import logoWhiteEnImg from '../images/gasser-partner-logo-white-en.svg'

import MenuNav from './MenuNav'
import MenuMetaNav from './MenuMetaNav'
import Linkedin from './Icons/Linkedin'
import CompetencesNav from './CompetencesNav'
import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 350px;
  height: auto;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 480px) {
    width: 280px;
    top: 52px;
    position: inherit;
    top: auto;
    left: auto;
    transform: none;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
`

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  z-index: 101;
  background: url(${officeImg}) center center no-repeat;
  background-size: cover;
  opacity: 0;
  transition: all 0.35s ease;
  visibility: hidden;
  will-change: opacity, visibility;

  ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const Sidebar = styled(animated.div)`
  background: ${props => props.theme.colors.primary};
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;

  @media (max-width: 480px) {
    display: block;
    height: 100vh;
    position: inherit;
    top: auto;
    right: auto;
    bottom: auto;
    overflow-y: auto;
  }
`

const Content = styled.div`
  max-width: 300px;
  text-align: center;

  @media (min-width: 480px) and (max-height: 860px) {
    transform: scale(0.8);
  }

  @media (max-width: 1080px) {
    max-width: 100%;
  }
`

const Social = styled.div`
  text-align: center;
  margin-top: 3rem;

  @media (max-width: 480px) {
    margin-bottom: 3rem;
  }
`

const SocialLink = styled.a`
  margin: 0 auto;
  background: #fff;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  color: ${props => props.theme.colors.primary};
  transition: background 0.35s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    background: transparent;
    color: #fff;
  }
`

/** Expertise */

const Expertise = styled(animated.div)`
  background: ${props => props.theme.colors.primary};
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 33.333333%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;
`

const ExpertiseContent = styled.div`
  width: 330px;
  max-width: 100%;
`

const tabletMediaQuery = '(min-width: 1080px)'
const desktopMediaQuery = '(min-width: 1560px)'

/**
 * < 1080: mobile size: sidebar: 100%; no expertise
 * > 1080: tablet size: siebbar: 50%;
 * > 1560: desktop size: sidebar: 50% / with expertise: 33.333333%
 */
const Menu = ({
  isOverlayOpen,
  isExpertiseOpen,
  onExpertiseToggle,
  onOverlayClose,
}) => {
  const intl = useIntl()
  const i18n = useContext(I18nContext)
  const [minTablet, setMinTablet] = useState(false)
  const [minDesktop, setMinDesktop] = useState(false)

  const data = useStaticQuery(graphql`
    query CompetenceQuery {
      allContentfulCompetence(sort: { fields: title }) {
        edges {
          node {
            node_locale
            id
            title
            slug
            content {
              json
            }
          }
        }
      }
    }
  `)

  const competencesAllLanguages = data.allContentfulCompetence.edges.map(
    edge => {
      const { node } = edge
      return Object.assign(node)
    }
  )

  const competences = competencesAllLanguages.filter(
    competence => competence.node_locale === i18n.currentLocale
  )

  // TODO: Create useMatchMedia hook to make this easier and not needing useEffect because or SSR.
  // TODO: Add support for change event on matchMediaQuery to update when screen size changes. Maybe with debounce, so it doesn't fire too often.
  useEffect(() => {
    const matchMinTablet = window.matchMedia(tabletMediaQuery).matches
    const matchMinDesktop = window.matchMedia(desktopMediaQuery).matches

    if (matchMinTablet) {
      setMinTablet(matchMinTablet)
    }

    if (matchMinDesktop) {
      setMinDesktop(matchMinDesktop)
    }
  }, [])

  const sidebarProps = useSpring({
    transform: isOverlayOpen ? 'translateX(0%)' : 'translateX(100%)',
  })

  let sidebarWidth
  if (minDesktop) {
    if (isExpertiseOpen) {
      sidebarWidth = '33.333333%'
    } else {
      sidebarWidth = '50%'
    }
  } else if (minTablet) {
    sidebarWidth = '50%'
  } else {
    sidebarWidth = '100%'
  }

  const sidebarWidthProps = useSpring({
    width: sidebarWidth,
  })

  const expertiseProps = useSpring({
    transform: isExpertiseOpen ? 'translateX(0%)' : 'translateX(-100%)',
  })

  const logoImg = i18n.currentLocale === 'de' ? logoWhiteDeImg : logoWhiteEnImg

  return (
    <Wrapper isOpen={isOverlayOpen}>
      <Expertise style={expertiseProps}>
        <ExpertiseContent>
          <CompetencesNav competences={competences} white />
        </ExpertiseContent>
      </Expertise>
      <Sidebar style={{ ...sidebarProps, ...sidebarWidthProps }}>
        <Link to="/">
          <Logo src={logoImg} alt={intl.formatMessage({ id: 'title' })} />
        </Link>
        <Content>
          <MenuNav
            onOverlayClose={onOverlayClose}
            isExpertiseOpen={isExpertiseOpen}
            minDesktop={minDesktop}
            onExpertiseToggle={onExpertiseToggle}
          />
          <MenuMetaNav onOverlayClose={onOverlayClose} />
          <Social>
            <SocialLink
              href="https://www.linkedin.com/company/gasser-partner-rechtsanwälte/"
              target="_blank"
              rel="nofollow noopener"
            >
              <Linkedin height={20} />
            </SocialLink>
          </Social>
        </Content>
      </Sidebar>
    </Wrapper>
  )
}

Menu.propTypes = {
  isOverlayOpen: PropTypes.bool,
  isExpertiseOpen: PropTypes.bool,
  onExpertiseToggle: PropTypes.func.isRequired,
}

export default Menu
