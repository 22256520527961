export const CONSENT_KEY = 'consent_status'
export const STATUS_ACCEPTED = 'ACCEPTED'
export const STATUS_DECLINED = 'DECLINED'

export const CONSENT_EXPIRATION = 7776000 // 90 days in seconds

function doNotTrack() {
  return navigator.doNotTrack === '1'
}

function consentAccepted() {
  const item = localStorage.getItem(CONSENT_KEY)
  if (!item) {
    return false
  }

  const now = new Date()
  const consent = JSON.parse(item)

  // Check expiration and if the item is expired, delete the item from storage.
  // getTime returns milliseconds and consent.expiry returns seconds => convert ms to s.
  if (now.getTime() / 1000 > consent.expiry) {
    localStorage.removeItem(CONSENT_KEY)
    return false
  }

  return consent.status === STATUS_ACCEPTED
}

function consentUndefined() {
  return localStorage.getItem(CONSENT_KEY) === null
}

function createConsentObject(status) {
  const now = new Date()
  return {
    expiry: Math.round(now.getTime() / 1000 + CONSENT_EXPIRATION),
    status,
  }
}

export function acceptConsent() {
  const consent = createConsentObject(STATUS_ACCEPTED)
  localStorage.setItem(CONSENT_KEY, JSON.stringify(consent))
}

export function declineConsent() {
  const consent = createConsentObject(STATUS_DECLINED)
  localStorage.setItem(CONSENT_KEY, JSON.stringify(consent))
}

export function shouldTrack() {
  if (!doNotTrack() && consentAccepted()) {
    return true
  }

  return false
}

export function shouldShowConsent() {
  if (!doNotTrack() && consentUndefined()) {
    return true
  }

  return false
}
