import config from '../config'

export default function getRoute(currentRoute, wantedLanguage) {
  if (!(currentRoute in config.i18n.routes)) {
    return '/'
  }

  if (!(wantedLanguage in config.i18n.routes[currentRoute])) {
    return '/'
  }

  return config.i18n.routes[currentRoute][wantedLanguage]
}
