import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'

import labelStyles from '../styles/label'

const MetaList = styled.ul`
  margin: 0;
  padding: 2rem 0;
  list-style: none;
  position: relative;

  &::after {
    content: '';
    height: 1px;
    width: 168px;
    background: #fff;
    display: block;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
`

const MetaItem = styled.li``

const MetaLink = styled(Link)`
  ${labelStyles};
  display: block;
  line-height: 0.75rem;
  text-decoration: none;
  padding: 0.75rem 0;

  &:hover,
  &.is-active {
    font-weight: bold;
  }
`

const MenuMetaNav = ({ onOverlayClose }) => {
  const intl = useIntl()

  return (
    <MetaList>
      <MetaItem>
        <MetaLink
          to={intl.formatMessage({ id: 'nav.contact.link' })}
          activeClassName="is-active"
          partiallyActive
          onClick={onOverlayClose}
        >
          <FormattedMessage id="nav.contact" />
        </MetaLink>
      </MetaItem>
      <MetaItem>
        <MetaLink
          to={intl.formatMessage({ id: 'nav.publications.link' })}
          activeClassName="is-active"
          partiallyActive
          onClick={onOverlayClose}
        >
          <FormattedMessage id="nav.publications" />
        </MetaLink>
      </MetaItem>
      <MetaItem>
        <MetaLink
          to={intl.formatMessage({ id: 'nav.career.link' })}
          activeClassName="is-active"
          partiallyActive
          onClick={onOverlayClose}
        >
          <FormattedMessage id="nav.career" />
        </MetaLink>
      </MetaItem>
    </MetaList>
  )
}

export default MenuMetaNav
