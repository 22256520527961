import { css } from 'styled-components'

import theme from '../theme'

export default css`
  ${theme.fonts.serif};
  font-weight: normal;
  letter-spacing: -0.011em; /* inherit from body? */
  font-size: 3rem; /* 48px */
  font-size: 50px;
  line-height: 1.125; /* 54px */

  @media (max-width: 540px) {
    font-size: 2.5rem;
  }

  @media (max-width: 380px) {
    font-size: 2.25rem;
  }
`
