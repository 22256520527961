import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'

import title1Styles from '../styles/title1'

const Wrapper = styled.nav`
  position: relative;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;

  &::before,
  &::after {
    content: '';
    height: 1px;
    width: 168px;
    background: #fff;
    display: block;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &::before {
    top: 0%;
  }

  &::after {
    bottom: 0;
  }

  @media (max-width: 480px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const NavItem = styled.li``

// TODO: WTF?
const NavLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  ${title1Styles}
  display: block;
  padding: 0.2rem 0;
  line-height: 1.3;
  text-decoration: none;

  &:hover,
  &.is-active {
    font-weight: bold;
  }

  ${props =>
    props.isActive &&
    css`
      font-weight: bold;
    `}
`

const MenuNav = ({
  isExpertiseOpen,
  onExpertiseToggle,
  minDesktop,
  onOverlayClose,
}) => {
  const intl = useIntl()
  const onClickExpertise = e => {
    // TODO: Move logic to Menu.js
    if (minDesktop) {
      onExpertiseToggle()
      e.preventDefault()
    } else {
      onOverlayClose()
    }
  }

  return (
    <Wrapper>
      <NavList>
        <NavItem>
          <NavLink
            to={intl.formatMessage({ id: 'nav.competences.link' })}
            activeClassName="is-active"
            partiallyActive
            onClick={onClickExpertise}
            isActive={isExpertiseOpen}
          >
            <FormattedMessage id="nav.competences" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={intl.formatMessage({ id: 'nav.about.link' })}
            activeClassName="is-active"
            partiallyActive
            onClick={onOverlayClose}
          >
            <FormattedMessage id="nav.about" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={intl.formatMessage({ id: 'nav.team.link' })}
            activeClassName="is-active"
            partiallyActive
            onClick={onOverlayClose}
          >
            <FormattedMessage id="nav.team" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={intl.formatMessage({ id: 'nav.news.link' })}
            activeClassName="is-active"
            partiallyActive
            onClick={onOverlayClose}
          >
            <FormattedMessage id="nav.news" />
          </NavLink>
        </NavItem>
      </NavList>
    </Wrapper>
  )
}

export default MenuNav
