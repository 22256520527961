import { css } from 'styled-components'

import theme from '../theme'

export default css`
  ${theme.fonts.sansSerif};
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-size: 12px;
`
