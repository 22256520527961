import React from 'react'
import styled from 'styled-components'

import bodyLargeStyles from '../../styles/bodyLarge'
import bodyMediumPlusStyles from '../../styles/bodyMediumPlus'

import mapSchaanImg from './assets/Map_Schaan.png'
import mapZurichImg from './assets/Map_Zurich.png'
import mapViennaImg from './assets/Map_Vienna.png'

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`

const Cols = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -20px;
  margin-left: -10px;
  margin-right: -10px;
`

const Col = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: 33.333333%;
  margin-bottom: 2.5rem;

  @media (max-width: 860px) {
    width: 50%;
  }

  @media (max-width: 580px) {
    width: 100%;
  }
`

const MapWrapper = styled.div`
  margin-bottom: 2rem;
  position: relative;
`

const TitleWrapper = styled.div``

const Title = styled.h2`
  ${bodyLargeStyles}
  margin: 0 0 0.8rem 0;
  display: inline-block;
  padding: 0 1rem 0.7rem 0;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
`

const Address = styled.address`
  ${bodyMediumPlusStyles}
  margin-bottom: 2.5rem;

  a {
    text-decoration: none;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

const ImageCopy = styled.span`
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3px 5px;
  background: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  color: #777777;
`

export const offices = [
  {
    id: 1,
    title: 'Office Schaan',
    street: 'Feldkircher Strasse 31',
    zip: '9494',
    city: 'Schaan',
    country: 'Liechtenstein',
    phone: '+423 236 30 80',
    fax: '+423 236 30 81',
    email: 'office@gasserpartner.com',
    link: 'https://maps.app.goo.gl/Z4Y9eQjqhnB4hm7V9',
    image: mapSchaanImg,
    copyright: '© Mapbox, © OpenStreetMap',
  },
  {
    id: 2,
    title: 'Office Zurich',
    street: 'Beethovenstrasse 19',
    zip: '8002',
    city: 'Zurich',
    country: 'Switzerland',
    phone: '+41 58 404 55 40',
    email: 'office@gasserpartner.com',
    link: 'https://goo.gl/maps/Ke8bE2KD6hiT6kq29',
    image: mapZurichImg,
    copyright: '© Mapbox, © OpenStreetMap',
  },
  {
    id: 3,
    title: 'Office Vienna',
    street: 'Tuchlauben 3',
    zip: '1010',
    city: 'Vienna',
    country: 'Austria',
    phone: '+43 1 310 33 11',
    email: 'office@gasserpartner.com',
    link: 'https://goo.gl/maps/fgcdbxVCTUaZUySSA',
    image: mapViennaImg,
    copyright: '© Mapbox, © OpenStreetMap',
  },
]

const Offices = () => (
  <Wrapper>
    <Cols>
      {offices.map(office => (
        <Col key={office.id}>
          <MapWrapper>
            <Image src={office.image} alt="" />
            <ImageCopy>{office.copyright}</ImageCopy>
          </MapWrapper>
          <TitleWrapper>
            <Title>{office.title}</Title>
          </TitleWrapper>
          <Address>
            {office.street}
            <br />
            {office.zip} {office.city}
            <br />
            {office.country}
            <br />T <a href={`tel:${office.phone}`}>{office.phone}</a>
            <br />
            <a href={`mailto:${office.email}`}>{office.email}</a>
          </Address>
        </Col>
      ))}
    </Cols>
  </Wrapper>
)

export default Offices
