import React, { useContext } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'
import getRoute from '../utils/getRoute'
import getDynamicRoute from '../utils/getDynamicRoute'
import labelStyles from '../styles/label'

const Wrapper = styled.div`
  ${labelStyles}
  position: absolute;
  top: 49px;
  left: 38px;
  z-index: 101; /* behind the menu overlay */
  ${props => props.theme.fonts.sansSerif}
  color: ${props => props.theme.colors.dark};
  opacity: 1;
  transition: opacity 0.35s ease;

  @media (max-width: 1170px) {
    left: 18px;
  }

  @media (max-width: 420px) {
    top: 20px;
  }

  ${props =>
    props.isOverlayOpen &&
    css`
      opacity: 0;
    `}
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  padding: 2px;
  transition: color 0.35s ease;

  &.active,
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

const Language = ({ isOverlayOpen }) => {
  const i18n = useContext(I18nContext)

  const deRoute = i18n.currentRoute
    ? getRoute(i18n.currentRoute, 'de')
    : getDynamicRoute(i18n.currentDynamicRoutes, 'de')

  const enRoute = i18n.currentRoute
    ? getRoute(i18n.currentRoute, 'en')
    : getDynamicRoute(i18n.currentDynamicRoutes, 'en')

  return (
    <Wrapper isOverlayOpen={isOverlayOpen}>
      <StyledLink to={deRoute} partiallyActive activeClassName="active">
        DE
      </StyledLink>{' '}
      |{' '}
      <StyledLink to={enRoute} partiallyActive activeClassName="active">
        EN
      </StyledLink>
    </Wrapper>
  )
}

export default Language
