import { css } from 'styled-components'

import title1Styles from './title1'

export default css`
  ${title1Styles}
  /* font-size: 1.75rem; */
  font-size: 1.875rem;

  @media (max-width: 540px) {
    font-size: 1.625rem;
  }

  @media (max-width: 380px) {
    font-size: 1.5rem;
  }
`
