import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li``

const StyledLink = styled(Link)`
  color: ${props => (props.white ? '#fff' : props.theme.colors.primary)};
  text-decoration: none;

  &:after {
    content: attr(title);
    display: block;
    font-weight: bold;
    visibility: hidden;
    height: 0;
    line-height: 0;
    transform: translateY(-100%);
    overflow: hidden;
  }
`

const Inner = styled.span`
  display: inline-block;
  padding: 0.375rem 0;
  transition: transform 0.25s;
  transform-origin: left center;
  transition: transform 0.25s;
  transform-origin: left center;
  position: relative;

  &::after {
    content: '';
    display: block;
    height: 1px;
    background: ${props =>
      props.white
        ? `linear-gradient(90deg, #fff, rgba(255, 255, 255, 0))`
        : `linear-gradient(90deg, #e63b28, rgba(255, 255, 255, 0))`};
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 100%;
    width: calc(100% + 20px);
    transform: scaleX(0);
    transition: transform 0.35s ease;
    transform-origin: left;
  }

  &:hover {
    font-weight: bold;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`

const CompetencesNav = ({ competences, white = false }) => {
  const i18n = useContext(I18nContext)

  // TODO: Find way to automate for other languages.
  const link =
    i18n.currentLocale === 'en' ? '/en/competences' : '/de/taetigkeitsbereiche'

  return (
    <List>
      {competences.map(item => (
        <Item key={item.id}>
          <StyledLink
            to={`${link}/${item.slug}`}
            white={white ? 1 : 0}
            title={item.title}
          >
            <Inner white={white ? 1 : 0}>{item.title}</Inner>
          </StyledLink>
        </Item>
      ))}
    </List>
  )
}

export default CompetencesNav
