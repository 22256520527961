import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, createGlobalStyle } from 'styled-components'

const Button = styled.button`
  width: 48px;
  height: 48px;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  position: fixed;
  top: 34px;
  right: 25px;
  z-index: 101;

  @media (max-width: 1170px) {
    right: 7px;
  }

  @media (max-width: 420px) {
    top: 6px;
  }
`

const Inner = styled.div`
  display: block;
  height: 16px;
  width: 21px;
  margin: 0 auto;
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 2px;
    width: 100%;
    display: block;
    background: ${props => props.theme.colors.primary};
    position: absolute;
    left: 0;
    transition: background 0.35s, transform 0.35s;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  ${props =>
    props.isOpen &&
    css`
      &::before,
      &::after {
        background: #fff;
      }

      &::before {
        transform: translateY(7px) rotate(-45deg);
      }

      &::after {
        transform: translateY(-7px) rotate(-135deg);
      }
    `}
`

const Bar = styled.div`
  display: block;
  width: 100%;
  height: 2px;
  background: ${props => props.theme.colors.primary};
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  opacity: 1;
  transition: opacity 0.35s;

  ${props =>
    props.isOpen &&
    css`
      opacity: 0;
    `}
`

const GlobalStyle = createGlobalStyle`
  body.has-scrollbar ${Button} {
    right: 40px;

    @media (max-width: 1170px) {
      right: 20px;
    }
  }
`

const MenuButton = ({ onClick, isOverlayOpen, hasScrollbar }) => (
  <>
    <GlobalStyle />
    <Button
      onClick={onClick}
      isOpen={isOverlayOpen}
      hasScrollbar={hasScrollbar}
      aria-label="Menü öffnen"
    >
      <Inner isOpen={isOverlayOpen}>
        <Bar isOpen={isOverlayOpen} />
      </Inner>
    </Button>
  </>
)

MenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOverlayOpen: PropTypes.bool,
  hasScrollbar: PropTypes.bool,
}

export default MenuButton
