import React, { useContext } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import Fade from 'react-reveal/Fade'
import { FormattedMessage } from 'react-intl'

import Content from './Content'
import labelStyles from '../styles/label'
import bodySmallStyles from '../styles/bodySmall'
import title3Styles from '../styles/title3'
import Brand from './Brand'
import { offices } from './Contact/Offices'
import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'

const Wrapper = styled.footer`
  background: ${props => props.theme.colors.primary};
  color: #fff;
  position: relative;

  a {
    text-decoration: none;
  }
`

const Inner = styled(Content)`
  padding-top: 1.25rem;
  padding-bottom: 3rem;
`

const Title = styled.h1`
  ${title3Styles}
  text-align: center;
  margin-bottom: 4rem;
  line-height: 1.3;
`

const Cols = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -20px;
`

const Col = styled.div`
  flex: 1;
  padding-left: 20px;
  margin-bottom: 1.5rem;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: auto;
  }

  ${props =>
    props.type === 'content' &&
    css`
      flex-basis: 50%;
    `}

  ${props =>
    props.type === 'office' &&
    css`
      flex-basis: 33.333333%;
    `}

  ${props =>
    props.type === 'hours' &&
    css`
      flex: auto;
      margin-bottom: 2rem;
    `}


    ${props =>
      props.type === 'copyright' &&
      css`
        flex: 0;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          flex: auto;
        }
      `}
`

const Label = styled.div`
  ${labelStyles};
  margin-bottom: 0.25rem;
  white-space: nowrap;
`

const Text = styled.div`
  ${bodySmallStyles}
  line-height: 22px;
  letter-spacing: 0.029em;
  white-space: nowrap;
`

const Copyright = styled.div`
  ${labelStyles};
  line-height: 18px;
`

const StyledBrand = styled(Brand)`
  position: absolute;
  top: 40px;
  bottom: auto;

  @media (max-width: 1140px) {
    top: auto;
    bottom: 40px;
  }
`

const Footer = () => {
  const i18n = useContext(I18nContext)

  return (
    <Wrapper>
      <Inner>
        <Fade duration={350}>
          <Title>
            <FormattedMessage id="footer.slogan" />
            <br />
            <a href="mailto:office@gasserpartner.com">
              office@gasserpartner.com
            </a>
          </Title>
        </Fade>
        <Cols>
          <Col type="content">
            <Cols>
              {offices.map(office => (
                <Col type="office" key={office.id}>
                  <Fade duration={350}>
                    <Label>
                      <FormattedMessage id={`office.${office.id}.title`} />
                    </Label>
                  </Fade>
                  <Fade duration={350}>
                    <Text>
                      <FormattedMessage id="contact.phone" />{' '}
                      <a href={`tel:${office.phone}`}>{office.phone}</a>
                      <br />
                      {office.fax && (
                        <>
                          F {office.fax}
                          <br />
                        </>
                      )}
                      <address>
                        {office.street}
                        <br />
                        {office.zip}{' '}
                        <FormattedMessage id={`office.${office.id}.city`} />
                        <br />
                        <FormattedMessage id={`office.${office.id}.country`} />
                      </address>
                    </Text>
                  </Fade>
                </Col>
              ))}
            </Cols>
          </Col>
          <Col type="content">
            <Cols>
              <Col type="hours">
                <Fade duration={350}>
                  <Label>
                    <FormattedMessage id="contact.hours.monday-thursday" />
                  </Label>
                </Fade>
                <Fade duration={350}>
                  <Text>
                    08:00 – 18:00
                    <br />
                    <br />
                    <Label>
                      <FormattedMessage id="contact.hours.friday" />
                    </Label>
                    08:00 – 17:00
                  </Text>
                </Fade>
              </Col>
              <Col type="copyright">
                <Fade duration={350}>
                  <Copyright>
                    ©&nbsp;2019&nbsp;GASSER&nbsp;PARTNER
                    <br /> <FormattedMessage id="footer.attorneys" />
                    <br />
                    <br />
                    <Link
                      to={
                        i18n.currentLocale === 'en'
                          ? '/en/privacy-policy'
                          : '/de/datenschutz'
                      }
                    >
                      <FormattedMessage id="link.data-protection" />
                    </Link>
                    <br />
                    <Link
                      to={
                        i18n.currentLocale === 'en'
                          ? '/en/imprint'
                          : '/de/impressum'
                      }
                    >
                      <FormattedMessage id="link.imprint" />
                    </Link>
                    <br />
                    <a
                      href={
                        i18n.currentLocale === 'en'
                          ? '/GAPA-AGB-EN.pdf'
                          : '/GAPA-AGB-DE.pdf'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="link.terms-conditions" />
                    </a>
                    <br />
                  </Copyright>
                </Fade>
              </Col>
            </Cols>
          </Col>
        </Cols>
      </Inner>
      <StyledBrand color="white" />
    </Wrapper>
  )
}

export default Footer
