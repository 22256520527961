import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'

import theme from '../../theme'
import labelStyles from '../../styles/label'
import Content from '../Content'
import {
  acceptConsent,
  declineConsent,
  shouldTrack,
  shouldShowConsent,
} from './utils'

const Wrapper = styled.div`
  background: ${theme.colors.primary};
  color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px 0;
  z-index: 1001;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
`

const Main = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`

const Text = styled.div`
  padding-right: 20px;

  @media (min-width: 1141px) {
    /** Make font a little smaller than body on larger screens. */
    font-size: 0.925em;
  }

  @media (max-width: 960px) {
    margin-bottom: 20px;
  }

  @media (max-width: 400px) {
    text-align: center;
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`

const buttonStyles = css`
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
`

const DeclineButton = styled.button`
  ${buttonStyles}
  ${labelStyles}
  padding: 1rem;
  margin-right: 20px;

  @media (max-width: 400px) {
    order: 2;
    margin-top: 10px;
    margin-right: 0;
    padding: 0.5rem;
  }
`

const AcceptButton = styled.button`
  ${buttonStyles}
  ${labelStyles}
  background: #fff;
  color: ${theme.colors.primary};
  padding: 1rem 2rem;
`

function Consent() {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  function onDecline() {
    declineConsent()
    setShow(false)
  }

  function onAccept() {
    acceptConsent()
    setShow(false)
    window.location.reload(false)
  }

  useEffect(() => {
    if (shouldTrack()) {
      // eslint-disable-next-line no-underscore-dangle
      window._paq.push(['setConsentGiven'])
    }

    if (shouldShowConsent()) {
      setShow(true)
    }
  }, [])

  if (!show) {
    return null
  }

  return (
    <Wrapper>
      <Content>
        <Main>
          <Text
            dangerouslySetInnerHTML={{
              __html: intl.formatHTMLMessage({ id: 'consent.info' }),
            }}
          />
          <Meta>
            <DeclineButton onClick={onDecline}>
              {intl.formatMessage({ id: 'consent.decline' })}
            </DeclineButton>
            <AcceptButton onClick={onAccept}>
              {intl.formatMessage({ id: 'consent.accept' })}
            </AcceptButton>
          </Meta>
        </Main>
      </Content>
    </Wrapper>
  )
}

export default Consent
