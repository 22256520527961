import { css } from 'styled-components'

import theme from '../theme'

export default css`
  ${theme.fonts.serif};
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.12em;
  font-size: 1rem;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.011em;

  @media (max-width: 1140px) {
    font-size: 18px;
  }

  @media (max-width: 680px) {
    font-size: 16px;
  }
`
